import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const UpvcPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - uPVC Fascias, Soffits and Guttering" />
      <IntroCopy
        headline="uPVC Fascias, Soffits and Guttering"
        body="Our skilled staff can carry out a variety of different styles of fascias and soffits to suit the individual property, whether it be uPVC Mamouth Board, uPVC Cap It, uPVC Clading or Wooden stained/painted fascia our staff have expertise to carry out a quality job."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="uPVC Fascias Soffits and Guttering"
        body="Fascias, soffits and guttering play a vital role in protecting your home. Available in a variety of colours, profiles and effects. Our skilled staff can advise you on the best solution for your property. We take great care in the finer details of our UPVC works ensuring the job is very pleasing to the eye."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "3")}
      />
      <FullWidthInfo
        headline="Fascias & Guttering, Before & After"
        body="Here we were called to a leaking gutter. On inspection it showed that the gutter was poorly aligned and the weight of the water in the gutter had lead to the poorly fixed fascia to come away from the wall and outside the line of the eaves. New UPVC Fascia was installed, properly fixed and correctly aligned guttering screwed to the front."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "4")}
        right={data.images.nodes.filter(image => image.name === "5")}
        withLabels
      />
    </main>
  </Layout>
);

export const query = graphql`
  query UpvcImages {
    images: allFile(filter: { relativeDirectory: { eq: "portfolio/upvc" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default UpvcPage;
